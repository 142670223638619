.errorPage {
  background: linear-gradient(45deg, rgb(0, 121, 176), rgb(16, 200, 254));
  /* background-image: url(./gradient-desktop-error.jpg); */
  height: 100vh;
  width: 100%;
  font-family: "ATT Aleck Sans";
  color: #fff;
  overflow: hidden;
  position: relative;
}

.ribbon-desktop-error {
  height: 100vh;
  width: 102%;
  right: -8%;
  position: absolute;
  object-fit: contain;
  top: 0px;
  object-position: top right;
}


@media only screen and (max-height: 800px) {
  .ribbon-desktop-error {
    right: -6%;
  }
}

@media only screen and (max-height: 750px) {
  .ribbon-desktop-error {
    right: -4%;
  }
}

@media only screen and (max-height: 600px) {
  .joy-to-world {
    max-width: 1000px;
    padding-top: 0;
  }

  .ribbon-desktop-error {
    right: -3%;
  }
}

@media only screen and (max-height: 450px) {
  .joy-to-world {
    max-width: 600px;
  }

  .desktop-err-text {
    padding: 0 !important;
    margin: 0 !important;
  }

  .ribbon-desktop-error {
    right: -1%;
  }
}

@media only screen and (max-height: 290px) {
  .joy-to-world {
    max-width: 400px;
  }

  .desktop-err-text {
    padding: 0;
    margin: 0;
    font-size: .8rem !important;
  }

  .ribbon-desktop-error {
    right: 0;
  }
}

.joy-to-world {
  filter: drop-shadow(0 0 1000px rgb(0, 121, 176));
  z-index: 1;
  width: 70%;
  padding-top: 5%;
}