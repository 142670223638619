.cookie {
  display: none;
}

.modalBackground {
  background: linear-gradient(45deg, rgb(51, 120, 172), rgb(91, 198, 249));
  color: #fff;
  height: 13rem;
}

.home {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  justify-content: space-around;
  flex-wrap: nowrap !important;
  background: url(../assets/images/linear-gradient-loading.png) no-repeat center;

  .logo {
    margin: 0;
  }
}

.alert {
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.accept {
  width: 65%;
  text-align: center;
  font-size: 0.875rem;
  margin-top: 0.4rem;
  padding: 0;
}

.static {
  height: 32vh;
}

@media screen and (orientation: landscape) {
  .home {
    height: 100%;
    width: 100%;
    justify-content: flex-start;

    .content {
      height: 100% !important;
      width: auto;
    }

    .cta {
      margin: 1rem !important;
    }

    .button {
      font-size: .75rem;
      height: 50px;
      width: 180px;
    }
  }
}

.unwrap-joy-ribbon-home {
  position: absolute;
  top: -20.5%;
  right: -1%;
  width: 103%;
  height: 100vh;
  object-fit: cover;
  object-position: top left;
}

@media only screen and (max-width: 410px) {
  .unwrap-joy-ribbon-home {
    object-fit: contain;
    top: -14%;
    right: -4%;
  }
}

.unwrap-joy-img-home {
  width: 50vw !important;
  height: auto !important;
  z-index: 10;
  object-fit: cover;
  object-position: top center;
}
 
.unwrap-joy-ribbon-horizontal-home {
  position: absolute;
  //right: -1%;
  width: 103%;
  height: 100vh;
  object-fit: cover;
  object-position: top right;
}

@media only screen and(orientation: portrait) {
  .unwrap-joy-ribbon-horizontal-home {
    display: none;
  }
}

@media only screen and (orientation: landscape) {
    .unwrap-joy-img-home {
      max-width: 170px !important;
      padding-top: 0;
      margin-top: 0 !important;
    }

  .unwrap-joy-ribbon-home {
    display: none;
  }
}


.yeti-circle {
  position: relative;
  z-index: 1;
  margin: 15% 0 auto 1%;
  border: 3px solid transparent;
  background: rgba(158, 221, 240, 0.7);
  border-radius: 50%;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  .yeti-img {
    width: 115%;
    height: auto;
  }
}

@media only screen and (orientation: landscape) {
  .yeti-circle {
    width: 92px;
    height: 92px;
  }
}