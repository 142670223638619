.svgContainer {
  position: relative;
  display: flex;
}

.svg {
  position: relative;
  z-index: 1;
}

.blue-circle {
  position: absolute;
  width: 32px;
  height: 32px;
  left: 45%;
  transform: translate(-50%, -50%);
  bottom: 6%;
  z-index: 0;
}

.heart-icon-container {
  position: relative;
  width: 65px;

  .blue-circle {
    left: 49%;
    bottom: auto;
    top: 47%;
  }
}

.heart-guage-container {
  display: flex;
  align-items: center;
  margin-left: -12px;

  .health-line {
    background-image: url('../../assets/images/HealthLine.png');
    height: 23px;
    width: 23px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 -2px 6px;
    transition: 0.3s;
    
    &.active {
      background-image: url('../../assets/images/HealthLineActive.png');
    }
  }
}

.streak-guage-container {
  position: absolute;
  height: 100%;
  left: -52px;
  top: -3px;
  transform: scale(0.32);

  .fill-to-show {
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
    transition: width 0.3s;

    #streak-guage-fill {
      position: absolute;
      left: 13px;
      top: 36px;
      transform: translateY(-50%);
    }
  }
}

.streak-0 {
  .fill-to-show {
    width: 0%
  }
}
.streak-1 {
  .fill-to-show {
    width: 33%
  }
}
.streak-2 {
  .fill-to-show {
    width: 66%
  }
}
.streak-3 {
  
  .fill-to-show {
    width: 100%
  }
}

// #streak-guage-sparkle {
//   position: absolute;
//   background: red;
//   right: -20px;
//   height: 100px;
// }

#map {
  width: 100px;
  margin: 5px
}

#multiplyer {
  position: absolute;
  right: 20px;
  color: #e4615d;
  font-size: 60px;
  top: 55%;
  transform: translateY(-50%);
}