// Header
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: rgb(0,160,220);
  background: linear-gradient(112deg, rgba(0,159,219,1) 41%, rgba(164,230,255,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .arrow {
    display: block;
    height: 20px;
    width: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    background: url('../assets/images/Arrow.png') no-repeat center;
    background-size: contain;
  }
  
  .animated {
    position: absolute;
    right: 0px;
    height: 30px;
    width: 47%;
  }

  .header-container {
    width: 53%;
    position: absolute;
    height: 60px;
    left: 0;
  }

  h2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;

    &.stationary {
      right: 0;
    }

    
    &.animating {
      left: 7px;
    }
  }
}

#target {
  width: 100%;
  height: 100%;
  position: absolute;
}

// Look for Cta Popup
#gradient {
  position: absolute;
  height: 160px;
  width: 100%;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  pointer-events: none;
  transition: 0.4s linear;

  h2 {
    width: 60%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 42px;
  }

  &.hide {
    display: none;
    transform: translateY(160px); 
  }
}

$modalHeight: 260px;

.target-found {
  width: 100%;
  height: $modalHeight;
  display: flex;
  position: absolute;
  bottom: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgb(0,160,220);
  background: linear-gradient(112deg, rgba(0,159,219,0.9) 41%, rgba(164,230,255,0.9) 100%);
  transition: all 0.5s ease;
  transform: translateY($modalHeight);
  
  @media (orientation: landscape) { 
    height: 180px;
  }
  
  &.slide-in {
    transform: translateY(0);

    .content {
      opacity: 1;
    }
  }

  .content {
    position: absolute;
    bottom: 32px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s linear 0.2s;

    h1 {
      font-size: 2.25rem;
      text-transform: uppercase;
      font-weight: bold;
      @media (orientation: landscape) { 
        font-size: 2rem;
        margin: -5px auto;
      }
    }

    p {
      margin: -10px auto 10px;
      
      @media (orientation: landscape) { 
        margin: -5px auto;
      }
    }
    .button {
      @media (orientation: landscape) { 
        margin-bottom: 0px;
      }
    }
  }
}

#target-indicator {
  width: 250px;
  top: 130px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (orientation: landscape) { 
    width: 150px;
    top: 80px;
  }
}

.zIndex {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
}

#legal {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-size: .625rem;
  color: #0057B8;
  font-weight: 300;
  padding: 10px 50px;
  font-family: 'ATT Aleck Cd';
}

#legal, .legal {
  &:focus {
    outline: none;
  }
}

.legal-modal {
  -webkit-overflow-scrolling: touch;
  position: absolute;
  background: #3DA3D5;
  z-index: 1000;
  padding: 40px;
  font-family: 'ATT Aleck Cd';
  text-align: left;
  overflow: auto;
  height: 100%;
  
  h3 {
    text-align: left;
    font-size: 1rem;
    font-weight: 800;
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 80px;
  }
}

.close-button {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 20px;
  right: 20px;

  &:before, &:after {
    content: '';
    width: 2px;
    height: 100%;
    background: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}