.ar-loading-screen {
  //background: rgb(0,160,220);
  background: url(./assets/images/linear-gradient-loading.png) no-repeat center;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: auto;

  .loading-animation {
    width: 100%;

    .circle {
      width: 180px;
      height: 180px;
      background: rgba(166, 236, 255, 0.5);
      background-size: cover;
      border-radius: 50%;
      margin: 0 auto;
      position: relative;

      @media (orientation: landscape) {
        width: 60px;
        height: 60px;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 180px;
        width: auto;

        @media (orientation: landscape) {
          height: 65px;
        }
      }
    }

  }

  .text {
    margin-top: 30px;
    text-align: center;

    @media (orientation: landscape) {
      margin-top: 10px;
    }

    h1 {
      margin-bottom: 30px;
      font-family: 'ATT Aleck Sans';

      @media (orientation: landscape) {
        font-size: 18px;
        margin: 10px auto;
      }
    }

    h2 {
      margin-top: 10px;
      letter-spacing: 0;
      margin-bottom: 0;

      @media (orientation: landscape) {}
    }

    p {
      font-size: .875rem;
      margin: 0px 0px 20px 0px;
    }
  }

  .eighth-wall-logo {
    width: 140px;
    height: auto;
    margin-top: 40px;

    @media (orientation: landscape) {
      margin-top: 00px;
      width: 80px;
    }
  }

  &.error {
    .loading-animation {
      top: 25%;

      @media (orientation: landscape) {
        top: 32%;
      }
    }

    .text {
      width: 90%;
      margin: 0px auto 20px;

      @media (orientation: landscape) {
        margin: 10px auto;
      }

      h1 {
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 15px;
        font-size: 2.25rem;
      }

      p {
        position: relative;
        margin: 2.5px;
        font-size: 1.375rem;
        letter-spacing: 0.13;
      }

      .button {
        margin: 30px auto 0;
        text-align: center;
      }

      .camera-instruction-button {
        background: transparent;
        box-shadow: none;
        font-weight: 800;
        font-size: 1.25rem;
        margin: 5px auto 10px;
      }
    }
  }
}

.logo {
  height: 7.8125rem;
  width: 9.25rem;
  margin: 25px auto 15px;

  @media (orientation: landscape) {
    height: 3.75rem;
    width: auto;
    margin: 10px auto 0;
  }

  @media (max-width: 321px) {
    height: 3.75rem;
    width: auto;
  }
}