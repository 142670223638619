.snow_container {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-filter: blur(4px);
  filter: blur(1.4px);
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
}

.snow-bg {
  background: linear-gradient(150deg, #009fdb, #a4e6ff);
  height: 100%;
  width: 100%;
}

.bokeh, .snow {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  // z-index: 0;
}
$bokehCount: 500;
$bokehBaseSize: 0.25rem;
$bokehBaseOpacity: 0.25;
$bokehBaseBrightness: 150;
$bokehBaseDuration: 16s;
$colorSet: (#53c7ed, #9ee1f6, #00a1e1, #7ecaec, #44bfe8, #47c3ed);
.bokeh div, .snow div {
  position: absolute;
  border-radius: 50%;
  animation-name: bokeh;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @for $i from 1 through $bokehCount {
    &:nth-child(#{$i}) {
      $randomizedSize: random() + $bokehBaseSize;
      width: $randomizedSize;
      height: $randomizedSize;
      top: random(100) * 1%;
      left: random(100) * 1%;
      color: nth($colorSet, random(length($colorSet)));
      opacity: $bokehBaseOpacity + random(4) * 0.15;
      box-shadow: ($randomizedSize + 5rem)
        0
        (0.2rem + random() * 0.5rem)
        currentColor;
      filter: brightness($bokehBaseBrightness + random(50) * 1%);
      transform-origin: (random(40)-20) * 1rem (random(40)-20) * 1rem;
      animation-duration: $bokehBaseDuration + random(30) * 1s;
    }
  }
}
@keyframes bokeh {
  100% {
    transform: rotate(360deg);
  }
}
