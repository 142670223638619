/* This fires as soon as the element enters the dom */
.modal-transition-enter,
.modal-transition-appear {
  /*We give the list the initial dimension of the list button*/
  bottom: 0;
  height: 0;
  display: block;

  .content {
    opacity: 0;
  }
}
/* This is where we can add the transition*/
.modal-transition-enter-active,
.modal-transition-appear-active {
  bottom: 0;
  height: 160px;
  transition: all 400ms;
  .content {
    transition: all 600ms 200ms;
    opacity: 1;
  }
}

// /* This fires as soon as the this.state.showList is false */
.modal-transition-exit {
  bottom: 0;
  height: 160px;
  transition: all 400ms;
  .content {
    opacity: 1;
  }
}
// /* fires as element leaves the DOM*/
.modal-transition-exit-active {
  bottom: 0;
  height: 0;

  .content {
    opacity: 0;
  }
}

.slide-down-enter {
  height: 160px;
  width: 100%;
  opacity: 1;
}

.slide-down-enter-active {
  transition: all 300ms;
}

.slide-down-exit-active {
  opacity: 0;
  transform: translateY(160px); 
}