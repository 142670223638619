/***** Fonts *****/
@font-face {
  font-family: 'ATT Aleck Cd';
  src: url('./assets/fonts/ATTAleckCd/ATTAleckCd-Bold.woff2') format('woff2'),
    url('./assets/fonts/ATTAleckCd/ATTAleckCd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('./assets/fonts/ATTAleckCd/ATTAleckSans-Medium.woff2') format('woff2'),
    url('./assets/fonts/ATTAleckCd/ATTAleckSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Cd';
  src: url('./assets/fonts/ATTAleckCd/ATTAleckCd-Medium.woff2') format('woff2'),
    url('./assets/fonts/ATTAleckCd/ATTAleckCd-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('./assets/fonts/ATTAleckCd/ATTAleckSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/ATTAleckCd/ATTAleckSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Cd';
  src: url('./assets/fonts/ATTAleckCd/ATTAleckCd-Light.woff2') format('woff2'),
    url('./assets/fonts/ATTAleckCd/ATTAleckCd-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('./assets/fonts/ATTAleckCd/ATTAleckSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/ATTAleckCd/ATTAleckSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ATT Aleck Sans';
  src: url('./assets/fonts/ATTAleckCd/ATTAleckSans-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/ATTAleckCd/ATTAleckSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Pallawa';
  src: url("./assets/fonts/Pallawa-6YaAq.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/***** Colors *****/

$red: #c0504d;
$blue: #00a0dc;


html,
body,
#root,
.app {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'ATT Aleck Sans', sans-serif;
  color: #fff;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
  text-align: center;
}

h1 {
  font-size: 1.375rem;
}

h2 {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 3.33px;
}

h3 {
  font-size: 2rem;
  font-weight: 800;
}

p {
  font-size: 1.2rem;
}

.button-md,
.button,
.MuiButtonBase-root.button {
  text-transform: uppercase;
  border: none;
  position: relative;
  font-family: 'ATT Aleck Sans', sans-serif;
  margin: 15px auto;
  padding: 0;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: .9rem;
  color: #0a71ae;
  background-color: #fff;
  width: 18.125rem;
  height: 3.9375rem;
  box-shadow: 0 2px 126px 0 rgba(4, 86, 131, 0.69);
  border-radius: 33px;
  text-align: center;

  @media (orientation: landscape) {
    width: 30vw;
    box-shadow: 0 2px 50px 0 rgba(4, 86, 131, 0.69);
  }
}

html.launched {

  &,
  body,
  #root,
  .app {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  #root {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .app {
    position: absolute;
    z-index: 2;
  }
}

.fancy {
  font-size: 6.875rem;
  font-family: 'Pallawa';
  margin: 0;
  line-height: 0.9;
  text-shadow: 0px 0px 30px rgba(51, 122, 207, 0.5);


  &.extra {
    font-feature-settings: "salt";
    font-size: 5.25rem;
  }
}

// 8th wall motion sensor dialog
.button-primary-8w {
  background-color: #009fdb;
}

#ar-canvas {
  position: absolute;
  top: 0;
}

.launched {
  #ar-canvas {
    position: relative;
    top: 0;
  }
}

.absolute-fill {
  height: 110%;
}

// *10/26/20 restyling ATT*
.rotate-your-screen-text-title {
  font-family: 'Pallawa';
  font-size: 6.50rem !important;
  margin: 12% 0 0 0;
  line-height: 0.4;
  text-shadow: 0px 0px 30px rgba(51, 122, 207, 0.5);
  z-index: 3;
  filter: drop-shadow(0 0 100px rgba(0, 121, 176, .2));
}

.rotate-your-screen-text-secondary {
  font-size: 1.6rem !important;
  line-height: 0.4;
  font-weight: 500;
  margin-bottom: 11%;
  z-index: 3;
}

@media only screen and (max-width: 400px) and (min-height: 800px) {
  .rotate-your-screen-text-title { 
    margin: 0% 0 0 0;
  }
}

//style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}
.rotate-landscape {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  min-height: 80px;
  margin-top: 9%;
  .landscape-view.rotate-your-screen-text-title {
    margin: 0 auto auto auto;
  }

  .landscape-view.rotate-your-screen-text-secondary {
    font-family: 'ATT Aleck Sans';
    margin: 0 auto auto auto;
  }

  @media only screen and (max-height: 350px) {
    min-height: 50px;
    .landscape-view.rotate-your-screen-text-title {
      font-size: 5em !important;
    }
    .landscape-view.rotate-your-screen-text-secondary {
      font-size: 1.5em !important;
    }
  }
}

.horizontal-ribbon-rotate {
  width: 102.5% !important;
  height: 102vh;
  top: -1%;
  position: absolute;
}

.vertical-ribbon-rotate {
  width: 100% !important;
  height: 100vh;
  right: -0%;
  top: -5%;
  position: absolute;
  z-index: 1;
}

.hide-overflow {
  position: relative;
  overflow: hidden;
  z-index: 1;
  //border-top: 1px rgba(148, 230, 255, 0.5) solid;
}

.let-it-throw-img {
  width: 13rem !important;
  height: 10rem !important;
  margin: 11% 0 11% 0;
}

.let-it-throw-ribbon {
  position: absolute;
  z-index: -1;
  height: 100vh !important;
  width: 100% !important;
  top: 0%;
}

@media only screen and (max-width: 300px) {
  .let-it-throw-ribbon {

    object-fit: contain;
    object-position: top left;
    
  }
  .let-it-throw-img {
    margin: 0 0 10% 0;
  }
}


.spacing-bottom {
  padding-bottom: -5px !important;
}

.internet-speed-sled-ribbon {
  position: absolute;
  z-index: -1;
  height: 220% !important;
  width: 180% !important;
  top: 4%;
}

.internet-speed-sled-img {
  position: relative;
  width: 14.5rem !important;
  height: 14.5rem !important;
  top: -5%;
  filter: drop-shadow(0 0 10px rgba(0, 121, 176, .2));
}

.copy.internet-speed-sled-content {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  /* Need a specific value to work */
  bottom: -30px;
  //top: 10px;
}

.transparent-blue-bg {
  background: linear-gradient(to top, rgba(0, 110, 161, .9), rgba(148, 230, 255, 0.5));
}
