@import '../index.scss';

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  padding: 0px 0;
  background-size: cover;
  background-position: center;
}

// ----------------- Colored Background ----------------- //
.overlay {
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: rgba(144,197,216, .5);
  background: linear-gradient(180deg, rgba(144,197,216,.5) 0%, rgba(25,149,198,0.4) 100%);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: 0.5s;

  &.pause {
    z-index: 1;
    background: transparent;
    background-image: url('../assets/images/PauseBackground.png');
    background-size: cover;
  }

  &.hide{
    opacity: 0;
  }
}

// ----------------- Frosty Edges ----------------- //
.frost {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  
  &:before, &:after {
    content: '';
    width: 100%;
    height: 30vh;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;

    @media (orientation: landscape) {
      height: 50vh;
    }
  }
  
  &:before {
    background-image: url('../assets/images/FrostBorderTop.png');
    top: 0;

    @media (orientation: landscape) {
      background-image: url('../assets/images/HorizontalFrostBorderTop.png');
    }
  }
  
  &:after {
    background-image: url('../assets/images/FrostBorderBottom.png');
    bottom: 0;
    background-position: bottom;
    @media (orientation: landscape) {
      background-image: url('../assets/images/HorizontalFrostBorderBottom.png');
    }
  }
}

.hit{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: transparent;
  transition: opacity .3s;
  background: rgba(0, 151, 238, 0.3);
  mix-blend-mode:color-burn; 
  opacity: 0;
  // background: linear-gradient(180deg, rgba(144,197,216,.5) 0%, rgba(25,149,198,0.4) 100%);
  &.active{
    opacity: 1;  
  }
}

.content {
  position: relative;
  width: 100%;
}

.title {
  width: 100%;
  margin: 40px auto;
  text-align: center;

  @media (orientation: landscape) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.copy {
  text-align: center;
  width: 75%;
  margin: 0 auto;

  strong {
    display: block;
  }
  
  @media (orientation: landscape) {
    width: 40%;
    margin: -25px auto 0;
  }

  p {
    text-shadow: 0px 0px 10px rgba(51, 122, 207, 0.68);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  
  @media (orientation: landscape) {
    width: 85%;
    margin: 0 auto;
    flex-direction: row;
  }

  button {
    margin: 10px 0;
  }
}

// ----------------- Close Button ----------------- //
.close {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background: #fff;

  @media (orientation: landscape) {
    right: 35px;
  }

  @media (max-height: 300px) {
    right: 32px;
    top: 18px;
  }

  &:before, &:after {
    content: '';
    width: 7px;
    height: 27px;
    background: $blue;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center;
    border-radius: 1px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}


// ----------------- Game Over ----------------- //

.game-over-horizontal-ribbon {
  width: 105% !important;
  height: 102vh;
  top: -1%;
  position: absolute;
}

.game-over-snowball {
  width: 100% !important;
  height: 100vh;
  right: -0%;
  top: -5%;
  position: absolute;
}

.game-over {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;

  .content {
    height: 21%;
  }

  .title {
    height: 100%;
    position: relative;

    @media (orientation: landscape) {
      flex-direction: row;
    }
    
    h1 {
      position: absolute;

      &:first-child {
        left: 15%;
        top: -15px;
      }

      &:last-child {
        right: 25%;
        bottom: -15px;

        @media (orientation: landscape) {
          left: 23%;
          font-size: 5.875rem;
          @media only screen and (max-width: 700px) {
            font-size: 4.875rem;
          }
        }
      }

      @media (orientation: landscape) {
        //position: unset;
        font-size: 6.5rem;
        margin: 0 auto 0 auto;
        left: 0;
        right: 0;
      }
    }
  }

  .buttons.landscape-game-over {
    //margin: 100px;
    width: 72%;
    .button {
      //margin: 0px;

    }
  }
  .score {
    color: #e4615d;
    display: flex;
    flex-direction: column;
    padding: 60px;
    position: relative;
    background-image: url('../assets/images/ScoreFrost.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  
    @media (orientation: landscape) {
      margin: 0 0 10px;
    }

    @media (max-height: 300px) {
      padding: 40px;
      margin: 30px 0 10px;
    }
    
    span {
      font-size: 1.25rem;
      margin-bottom: -10px;
      text-align: center;
    }

    h3 {
      font-size: 3.5rem;
      margin: 0;

      @media (orientation: landscape) {
        margin-bottom: -11px;
      }

      @media (max-height: 300px) {
        font-size: 2.75rem;
        margin: 5px 0 -10px;
      }
    }
  }
}

.buttons {
  @media (orientation: landscape) {
    margin-top: -50px;

    button {
      width: 40vw;
    }
  }
}

// ----------------- Pause Screen ----------------- //
.game-paused {
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;

  .title {
    position: relative;
    z-index: 1;
    text-align: center;
    left: -10px;
    top: -40px;
    height: 150px;
    
    @media (orientation: landscape) {
      width: 105%;
      display: flex;
      flex-direction: row;
      height: 90px;
      top: 0px;

      @media only screen and (max-width: 590px) {
        left: -11%;
      }
      
    }
    
    @media (max-height: 300px) {
      top: 0;
    }

    h1 {
      position: absolute;
      -webkit-user-select: none; /* Safari */        
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */

      @media (max-height: 300px) {
        font-size: 6rem;
      }

      &:first-child {
        top: 40px;
        left: 15%;

        @media (orientation: landscape) {
          top: -15px;
          margin: auto;
          &::after {
            content: " Paused";
          }
        }
        
        @media (max-height: 300px) {
          left: 27%;
        }
      }
      
      &:last-child {
        bottom: -60px;
        right: 10%;
        
        @media (orientation: landscape) {
          display: none;
        }

        @media (max-height: 300px) {
          bottom: 40px;
        }
      }
    }
  }

  .buttons {
    margin-top: 60px;

    @media (orientation: landscape) {
      margin: 0px auto 0;
      width: 85%;
      button {
        margin: 0 5px;
      }
    }
    
    @media (max-height: 300px) {
      margin: -60px auto 30px;
    }
  }
}

// ----------------- HUD ----------------- //
.hud {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  
  .controls-container {
    position: absolute;
    width: 50%;
    @media (orientation: landscape) {
      left: 12px;
    }
  }
  
  @media (orientation: landscape) {
    position: relative;
    height: 100%;

    .row {
      margin-bottom: 0;
    }
  }
  .row {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-bottom: -25px;
    height: 70px;

    @media (orientation: landscape) {
      height: 86px;
    }

    p {
      font-family: 'ATT Aleck Cd';
      font-size: 38px;
      margin: 3px 0 0 -10px;
      
      @media (orientation: landscape) {
        @supports (-webkit-overflow-scrolling: touch) {
          margin: -6px 0 0 -10px;
        }
      }
    }

    #timer {
      width: 60px;
      height: 60px;
    }

    #health, #streak {
      width: 170px;
      height: auto;
    }
  }
  
  .reposition-container {
    position: absolute;
    z-index: 3;
    bottom: 5px;
    right: 0px;
    width: 86px;
    height: 90px;

    #reposition {
      width: 100%;
      height: 100%;
    }

    @media (orientation: landscape) {
      right: 10px;
    }
  }

  .pause-container {
    position: absolute;
    z-index: 3;
    display: flex;
    z-index: 3;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    top: -25px;
    right: 10px;
    width: 80%;
    
    @media (orientation: landscape) {
      top: -10px;
      right: 32px;
    }

    .score {
      font-size: 38px;
      color: #fff;
      font-weight: bold;
      text-align: right;
      text-shadow: 0px 0px 6px rgba(0, 39, 75, 0.35);
    }

    .pause-button {
      margin: 10px 0 10px 10px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #fff;
      position: relative;
      z-index: 100000;
      box-shadow: 0px 0px 6px rgba(0, 39, 75, 0.35);

      @media (orientation: landscape) {
        margin-top: 25px;
      }

      @media (max-height: 300px) {
        margin-top: 28px;
      }
      
      &:before, &:after {
        transition: 0.25s linear;
        content: '';
        width: 8px;
        height: 23px;
        background: $blue;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform-origin: center;
        border-radius: 1px;
      }

      &:before {
        transform: translate(41%, -50%);
      }
      &:after {
        transform: translate(-140%, -50%);
      }
    }
  }

  .close {
    position: absolute;
    z-index: 999;
    right: 10px;
  }

  .swipe-to-throw {
    position: absolute;
    bottom: 180px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    transition: .3s;

    @media only screen and (min-width: 414px) {
      bottom: 210px;
    }

    &.hide{
      opacity: 0;
    }

    p {
      font-size: 12px;
      font-weight: 800;
      text-transform: uppercase;
    }

    img {
      width: 100px;
      margin: 0 auto;
      display: block;
    }
  }

  .gaming-arrows {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    pointer-events: none;

    .arrows {
      position: absolute;
      width: 60px;
      opacity: 0.75;
      top: 50%;
      transform: translateY(-50%);

      &.down {
        right: 40px;
        transform: translateY(-51%) rotate(180deg);
        transform-origin: center center;
      }

      &.up {
        left: 40px;
      }
    }
  }
}

.arrow-container{
  height: 40px;
  position: absolute;
  width: 100%;
  top: 40%;
  left: 0;
  transform: translateY(-50%);
  img.arrow{
    position: absolute;
    top: 0;
    height: 40px;
    width: auto;
    opacity: 0;
    transition: opacity .3s;
    &.show{
      opacity: 1;
    }
    &.left{
      left: 10px;
      transform: scaleX(-1);
    }
    &.right{
      right: 10px;
    }
  }
}

.instructions {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;

  &.mounted {
    opacity: 1;
  }

  .instruction-screen {
    width: 80% !important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 30px;
    padding: 20px 0 0;
    
    @media (orientation: landscape) {
      padding: 0;
      margin: 40px auto;
      width: 50% !important;
      justify-content: center;
      
      &.wide {
        width: 70% !important;
      }
      p {
        font-size: 1.1rem;
        margin: 0;
      }
    }
    
    @media (max-height: 300px) {
      margin: 0 auto;
      height: 180px;
    }
  }

  p {
    text-align: center;
    font-size: 1.55rem;
    
    @media (orientation: landscape) {
      font-size: 1.25rem;
    }
  }

  .image-with-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
  }
  
  .image-with-icon{
    display: flex;
    flex-direction: row;
    height: 36px;
    justify-content: center;
    align-items: center;
    margin: 0 4px;

    img {
      height: 100%;
      margin: 0 5px;
    }

    #timer {
      margin-top: -5px;
      height: 41px;
    }

    p {
      font-size: 2rem;
      font-weight: 800;
      margin: 0;
    }
  }
  .image-container {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: -15px 0 -15px;
    
    @media (orientation: landscape) { 
      margin: 8px 0;
      width: 70%;
    }

    img {
      width: 100px;
      height: 100px;
      margin: 5px 0 0;
      
      @media (orientation: landscape) { 
        width: 33px;
        height: 33px;
        margin: 0;
      }
    }

    .guage {
      width: 220px;
      height: 90px;
      
      &.health {
        width: 200px;
        height: 75px;
      }
    }

    .svgContainer {
      margin: 10px -20px 0 0;
      @media (orientation: landscape) { 
        transform: scale(0.9);
        margin: 0 -22px 0 0;
      }
    }

    p {
      font-size: 2.5em;
      font-weight: 800;
      margin: 0 5px;
      
      @media (orientation: landscape) { 
        margin-top: 8px;
        font-size: 2em;
      }
    }
  }

  .icon-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    // margin-top: 40px;

    .icon-container {
      width: 33%;

      p {
        margin-top: 0px;
        padding: 0 25px;
      }
    }

    #speed {
      width: 130px;
      margin: 0 auto;
      transform: scale(-1);
    }

    #shield {
      width: 80px;
      margin: 0 auto;
    }

    #more-time {
      width: 90px;
      margin: 0 auto;
    }
  }

  span {
    width: 100%;
    font-size: 1rem;
    font-weight: 800;
    letter-spacing: 2px;
    display: block;
    position: absolute;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: 7%;
    
    @media (orientation: landscape) {
      position: absolute;
      left: auto;
      width: auto;
      right: 20%;
      bottom: 17.5%;
    }

    @media (max-height: 300px) {
      bottom: 15%;
    }

  }

  .button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

#two-thumbs {
  width: auto;
  height: 80px;
  @media (orientation: landscape) {
    height: 40px;
  }
}

.landing-screen{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
  opacity: 0;
  transition: 0.5s;

  &.mounted {
    opacity: 1;
  }
  
  img {
    width: 100px;
    height: 100px;
  }

  @media (orientation: landscape) {
    p {
      font-size: 1rem;
    }

    img {
      width: 70px;
      height: 70px;
      display: inline-block;
      margin: -15px 0;
    }

    .button { 
      font-size: .875rem;
      height: 50px;
      width: 80%;
    }
  }
}

#feet {
  padding: 8px;
  background: rgba(0, 161, 219, 0.8);
  border-radius: 50%;
  border: 4px solid #fff;
  line-height: 1;
  font-weight: 800;
  margin: -2px 0 0 5px;
  font-size: 12px;
}

.rotate {
  height: 100%;
  
  .content {
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    margin-bottom: -20px;
  }

  h1 {
    font-size: 5.2rem;
  }
  
  img {
    width: 300px;
  }

  @media (orientation: landscape) {
    .title {
      margin-bottom: -40px;
      flex-direction: row;
    }
    h1 {
      &:last-child {
        margin-left: 15px;
      }
    }
    img {
      width: 220px;
    }
  }
}

.wave-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2, h3 {
    font-weight: 400;
    letter-spacing: -4.5px;
    text-transform: none;
  }
}

.slick-dots {
  bottom: 100px !important;

  @media (orientation: landscape) {
    bottom: 90px !important;
  }

  li {
    color: transparent;
    button {
      margin: -2px 0 0;
      &:before {
        height: 10px !important;
        width: 10px !important;
        color: transparent !important;
        border-radius: 50%;
        opacity: 1 !important;
        border: 1px solid #fff !important;
        margin: 8px;
      }
    }
  }
  
  .slick-active {
    button {
      margin: 0;
      &:before {
        margin-top: 7px;
        border-radius: 50% !important;
        opacity: 1 !important;
        color: #fff !important;
        height: 15px !important;
        width: 15px !important;
        background: #fff !important;
      }
    }
  }
}

.slick-prev {
  display: none !important;
}
