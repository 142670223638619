html {
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
}

.app {
  height: 100%;
}